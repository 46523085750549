import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { CartContext } from '../context/CartContext';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
// import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import useMediaQuery from '@mui/material/useMediaQuery';
import './Header.css';

const PASSWORD_KEY = "admin_access";
const PASSWORD_VALUE = "enzo10"; // 🛑 Change ici ton mot de passe si nécessaire

const isAuthenticated = () => {
  const savedAccess = localStorage.getItem(PASSWORD_KEY);
  if (!savedAccess) return false;

  try {
    const { value, expires } = JSON.parse(savedAccess);
    if (new Date() > new Date(expires)) {
      localStorage.removeItem(PASSWORD_KEY); // Supprime l'accès expiré
      return false;
    }
    return value === PASSWORD_VALUE;
  } catch {
    return false;
  }
};

const Header = ({ cartIconRef }) => {
  const [categories, setCategories] = useState([]);
  const { cart } = useContext(CartContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const isMobile = useMediaQuery('(max-width:768px)'); // Media query to check for mobile screens

  const [isAdmin, setIsAdmin] = useState(isAuthenticated());

useEffect(() => {
  setIsAdmin(isAuthenticated());
}, []);

  useEffect(() => {
    axios.get('https://back.atoneo.com/api/joli-mot-categories?populate=*')
      .then(response => {
        setCategories(response.data.data);
      })
      .catch(error => {
        console.error('There was an error fetching the categories!', error);
      });
  }, []);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  // Menu items for Drawer (on mobile) and normal (on desktop)
  const menuItems = (
    <>
      <List>
        <ListItem button component={Link} to="/">
          <ListItemText primary="Accueil" />
        </ListItem>
        <ListItem button component={Link} to="/">
          <ListItemText primary="Toutes nos cartes" />
        </ListItem>
        <ListItem button aria-haspopup="true" onClick={handleMenuClick}>
          <ListItemText primary="Catégories" />
        </ListItem>
        <ListItem button component={Link} to="/carte-a-gratter-digitale-questions-frequentes">
          <ListItemText primary="FAQ" />
        </ListItem>

        <ListItem button component={Link} to="/contact">
          <ListItemText primary="Contact" />
        </ListItem>


        {isAdmin && (
          <>
          <ListItem button component={Link} to="/gestion-commandes" className="btnMenuAdmin">
            <ListItemText primary="Gest. Commandes" />
          </ListItem>
          <ListItem button component={Link} to="/gestion-promos" className="btnMenuAdmin">
            <ListItemText primary="Gest. Codes Promo" />
          </ListItem>
          </>
        )}
      </List>
      <Divider />
      {categories.length > 0 ? (
        categories.map((category) => (
          <MenuItem
            key={category.id}
            component={Link}
            to={`/category/${category.attributes.idCateg}`}
            onClick={handleMenuClose}
          >
            {category.attributes.categoryName}
          </MenuItem>
        ))
      ) : (
        <MenuItem disabled>Aucune catégorie</MenuItem>
      )}
    </>
  );

  return (
    <AppBar position="static" sx={{ backgroundColor: '#c48f8b', padding: '10px 20px' }}>
      <Toolbar>
        <Typography
          variant="h6"
          component={Link}
          to="/"
          sx={{ flexGrow: 1, textDecoration: 'none', fontSize: 30, color: 'white', fontFamily: 'Neucha, cursive' }}
        >
          Joli P'tit Mot
        </Typography>

        {isMobile ? (
          <>
            {/* IconButton for mobile menu */}
            <IconButton edge="end" color="inherit" onClick={toggleDrawer(true)}>
              <MenuIcon />
            </IconButton>
            {/* Drawer for mobile */}
            <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
              <div
                role="presentation"
                onClick={toggleDrawer(false)}
                onKeyDown={toggleDrawer(false)}
              >
                {menuItems}
              </div>
            </Drawer>
          </>
        ) : (
          <>
            {/* Desktop navigation */}
            <Button color="inherit" component={Link} to="/">Accueil</Button>
            <Button color="inherit" component={Link} to="/">Toutes nos cartes</Button>
            <Button color="inherit" component={Link} to="/carte-a-gratter-digitale-questions-frequentes/">FAQ</Button> {/* Utilisation de /faq */}
            <Button color="inherit" component={Link} to="/contact/">Contact</Button> {/* Utilisation de /faq */}
            {/* <Button
              color="inherit"
              aria-controls="categories-menu"
              aria-haspopup="true"
              onClick={handleMenuClick}
            >
              Catégories
            </Button>
            <Menu
              id="categories-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              {categories.length > 0 ? (
                categories.map(category => (
                  <MenuItem
                    key={category.id}
                    component={Link}
                    to={`/category/${category.attributes.idCateg}`}
                    onClick={handleMenuClose}
                  >
                    {category.attributes.categoryName}
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled>Aucune catégorie</MenuItem>
              )}
            </Menu> */}
          </>
        )}

{isAdmin && !isMobile && (
  <>
    <Button color="inherit" component={Link} to="/gestion-commandes" className="btnMenuAdmin">
      Gest. Commandes
    </Button>
    <Button color="inherit" component={Link} to="/gestion-promos" className="btnMenuAdmin">
      Gest. Codes Promo
    </Button>
  </>
)}



        <IconButton color="inherit" component={Link} to="/cart" ref={cartIconRef}>
          <Badge badgeContent={cart.length} color="secondary">
            <ShoppingCartIcon />
          </Badge>
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

export default Header;

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';

import { getLabelProperties } from './LabelEtiquette';

import ReactMarkdown from 'react-markdown';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const ProductList = () => {
  const [products, setProducts] = useState([]);
  const [pageContent, setPageContent] = useState('');
  const [featuredImage, setFeaturedImage] = useState(null);
  const VAT_RATE = 0.2;

  useEffect(() => {
    axios.get('https://back.atoneo.com/api/joli-mot-prods?populate=*&sort=idProd:asc')
      .then(response => {
        setProducts(response.data.data);
      })
      .catch(error => {
        console.error('There was an error fetching the products!', error);
      });
  }, []);

  useEffect(() => {
    axios.get('https://back.atoneo.com/api/joli-mot-pages/1/?populate=*')
      .then(response => {
        const contentData = response.data.data.attributes;
        setPageContent(contentData.Contenu);
        
        if (contentData.featuredImage?.data?.attributes?.url) {
          setFeaturedImage(`https://back.atoneo.com${contentData.featuredImage.data.attributes.url}`);
        }
      })
      .catch(error => {
        console.error('There was an error fetching the page content!', error);
      });
  }, []);

  return (
    <Box className='boxAllProds' sx={{ padding: 3, position: 'relative' }}>
      <Typography variant="h1" sx={{ marginBottom: 4, textAlign: 'center' }}>
        Nos Produits
      </Typography>
      <Grid className='boxGridProd' container spacing={3}>
        {products.map(product => {
          const priceTTC = (product.attributes.Prix / 100) * (1 + VAT_RATE);

          // Extraction des images du produit
          const images = [
            product.attributes.featuredImage?.data?.attributes?.formats?.large?.url,
            ...product.attributes.Gallerie?.data.map(image => image.attributes.formats.large.url)
          ];

          return (
            <Grid item xs={12} md={4} lg={4} xl={4} key={product.id}>
              <Card sx={{ position: 'relative' }}>
                {/* Utiliser Link pour rendre toute la Card cliquable */}
                <Link to={`/produit/${product.attributes.url}`} style={{ textDecoration: 'none' }}>
                  <Carousel
                    showThumbs={false}
                    showArrows={false}
                    infiniteLoop={true}
                    autoPlay={true}
                    interval={Math.floor(Math.random() * (3000 - 2000 + 1) + 2000)}
                    stopOnHover={false}
                    swipeable={false}
                    transitionTime={800}
                  >
                    {images.map((url, index) => (
                      <Box
                        key={index}
                        sx={{
                          width: '100%',
                          aspectRatio: '1', // Assure un affichage carré
                          overflow: 'hidden',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <img
                          src={`https://back.atoneo.com${url}`}
                          alt={`Produit ${index + 1}`}
                          style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                            transition: 'transform 0.8s ease-in-out' // Smooth transition
                          }}
                        />
                      </Box>
                    ))}
                  </Carousel>
                </Link>
                
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    {product.attributes.Titre}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {product.attributes.DescriptionCourte}
                  </Typography>
                  <Typography variant="h6" color="primary" sx={{ marginTop: 1 }}>
                    {priceTTC.toFixed(2)} € TTC
                  </Typography>
                  <Button
                    variant="contained"
                    color="secondary"
                    sx={{ marginTop: 2 }}
                    component={Link}
                    to={`/produit/${product.attributes.url}`}
                  >
                    Voir Détails
                  </Button>
                </CardContent>
                
                {product.attributes.etiquette && (() => {
                    const { backgroundColor, icon } = getLabelProperties(product.attributes.etiquette);
                    
                    return (
                        <Chip
                            label={product.attributes.etiquette}
                            style={{ backgroundColor, color: '#FFFFFF' }}
                            icon={icon}
                            sx={{ position: 'absolute', top: 10, right: 10 }}
                        />
                    );
                })()}
              </Card>
            </Grid>
          );
        })}
      </Grid>

      <Box sx={{ marginTop: 5 }}>
        <ReactMarkdown>{pageContent}</ReactMarkdown>
      </Box>

      {featuredImage && (
        <img
          src={featuredImage}
          alt="Featured"
          style={{
            position: 'absolute',
            top: 0,
            right: 0,
            width: '150px',
            height: '150px',
            objectFit: 'cover',
            borderRadius: '50%',
            margin: '10px'
          }}
        />
      )}
    </Box>
  );
};

export default ProductList;

import React, { useState, useEffect } from "react";
import { Button, Typography } from "@mui/material";

const PASSWORD_KEY = "admin_access";
const PASSWORD_VALUE = "enzo10"; // 🛑 Change ici ton mot de passe

const isPasswordValid = () => {
  const savedAccess = localStorage.getItem(PASSWORD_KEY);
  if (!savedAccess) return false;

  try {
    const { value, expires } = JSON.parse(savedAccess);
    if (new Date() > new Date(expires)) {
      localStorage.removeItem(PASSWORD_KEY); // Supprime l'accès expiré
      return false;
    }
    return value === PASSWORD_VALUE;
  } catch {
    return false;
  }
};

const PasswordProtect = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(isPasswordValid());
  const [password, setPassword] = useState("");

  const handleLogin = () => {
    if (password === PASSWORD_VALUE) {
      const expirationDate = new Date();
      expirationDate.setDate(expirationDate.getDate() + 10); // Expire après 10 jours
      localStorage.setItem(PASSWORD_KEY, JSON.stringify({ value: PASSWORD_VALUE, expires: expirationDate }));
      setIsAuthenticated(true);
    } else {
      alert("Mot de passe incorrect !");
    }
  };

  if (!isAuthenticated) {
    return (
      <div style={{ padding: "20px", textAlign: "center" }}>
        <Typography variant="h4" gutterBottom>Accès Restreint</Typography>
        <Typography variant="body1">Veuillez entrer le mot de passe :</Typography>
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Mot de passe"
          style={{ padding: "10px", margin: "10px", width: "200px", textAlign: "center" }}
        />
        <Button variant="contained" color="primary" onClick={handleLogin}>
          Valider
        </Button>
      </div>
    );
  }

  return <>{children}</>;
};

export default PasswordProtect;

import React, { useContext, useEffect, useState } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet'; // Import Helmet
import axios from 'axios';
import { CartContext } from '../context/CartContext'; 
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Chip from '@mui/material/Chip';

import { getLabelProperties } from './LabelEtiquette';

import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import ReactMarkdown from 'react-markdown';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const ProductDetail = () => {
  const { url } = useParams();
  const navigate = useNavigate();
  const { addToCart } = useContext(CartContext);
  const [product, setProduct] = useState(null);
  const [customText, setCustomText] = useState('');
  const [selectedVisual, setSelectedVisual] = useState('');
  const [currentSlide, setCurrentSlide] = useState(0);
  const [markdownSections, setMarkdownSections] = useState([]);
  const VAT_RATE = 0.2;

  useEffect(() => {
    axios.get(`https://back.atoneo.com/api/joli-mot-prods?filters[url][$eq]=${url}&populate=*`)
      .then(response => {
        const productData = response.data.data?.[0]?.attributes;
        if (productData) {
          setProduct(productData);
          setSelectedVisual(productData.featuredImage?.data?.attributes?.formats?.medium?.url || '');
          processMarkdownSections(productData.Description);
        } else {
          navigate('/404');
        }
      })
      .catch(error => {
        console.error('Erreur lors du chargement du produit:', error);
      });
  }, [url, navigate]);

  const processMarkdownSections = (markdownText) => {
    const sections = [];
    const lines = markdownText.split('\n');
    let currentSection = null;

    lines.forEach(line => {
      const headingMatch = line.match(/^(#{1,6})\s\*\*(.+?)\*\*/); // Detect heading with bold markdown
      if (headingMatch) {
        if (currentSection) sections.push(currentSection);
        const level = headingMatch[1].length;
        const cleanTitle = headingMatch[2]; // Remove '**' by capturing text between
        currentSection = { title: cleanTitle, content: '', level };
      } else if (currentSection) {
        currentSection.content += `${line}\n`;
      }
    });
    if (currentSection) sections.push(currentSection);
    setMarkdownSections(sections);
  };

  const handleAddToCart = () => {
    if (product && customText.trim() !== '') {
      addToCart({
        id: product.id,
        name: product.Titre,
        price: product.Prix,
        customText,
        selectedVisual,
        featuredImage: selectedVisual,
      });

      // Faire défiler vers le haut de la page
      window.scrollTo({ top: 0, behavior: 'smooth' });

      toast.success(`${product.Titre} a été ajouté au panier!`, {
        position: "top-right", // Changer la position en haut à droite
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const handleVisualSelection = (url) => {
    setSelectedVisual(url);
    const selectedIndex = visuals.indexOf(url);
    if (selectedIndex >= 0) {
      setCurrentSlide(selectedIndex);
    }
  };

  const HeadingComponent = ({ title, level }) => {
    const variant = `h${level}`;
    return (
      <Typography component={variant} variant={variant}>
        {title}
      </Typography>
    );
  };

  if (!product) return <p>Loading...</p>;

  const priceTTC = (product.Prix / 100) * (1 + VAT_RATE);

  const images = [
    product.featuredImage?.data?.attributes?.formats?.large?.url,
    ...product.Gallerie?.data.map(image => image.attributes.formats.large.url)
  ];

  const visuals = [
    product.featuredImage?.data?.attributes?.formats?.medium?.url,
    ...product.Gallerie?.data.map(image => image.attributes.formats.medium.url)
  ];

  return (
    <>
     {/* SEO Helmet */}
     <Helmet>
        <title>{product.seo_title}</title>
        <meta
          name="description"
          content={product.seo_desc}
        />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <Grid container spacing={4} sx={{ marginTop: 0, marginBottom: 0 }}>
        <Grid item xs={12} md={6}>
          <Carousel 
            showThumbs={false} 
            infiniteLoop={true} 
            dynamicHeight={false} 
            selectedItem={currentSlide}
            onChange={(index) => setCurrentSlide(index)}
          >
            {images.map((url, index) => (
              <div key={index}>
                <img
                  src={`https://back.atoneo.com${url}`}
                  alt={`Produit ${index + 1}`}
                  style={{ borderRadius: '4px' }}
                />
              </div>
            ))}
          </Carousel>
        </Grid>

        <Grid item xs={12} md={6} sx={{ position: 'relative' }}>
          {product.etiquette && (() => {
              const { backgroundColor, icon } = getLabelProperties(product.etiquette);
              
              return (
                  <Chip
                      label={product.etiquette}
                      style={{ backgroundColor, color: '#FFFFFF' }}
                      icon={icon}
                      sx={{ position: 'absolute', top: -20, right: 0 }}
                  />
              );
          })()}

          <Typography color="primary" gutterBottom className='titreProd'>
            {product.Titre}
          </Typography>

          <Typography color="secondary" gutterBottom className='prixProd'>
            {priceTTC.toFixed(2)} € TTC
          </Typography>

          <Box sx={{ marginTop: 3 }}>
            <TextField
              label="Texte de personnalisation"
              variant="outlined"
              fullWidth
              multiline
              rows={3}
              inputProps={{ maxLength: 150 }}
              value={customText}
              onChange={(e) => setCustomText(e.target.value)}
              helperText={`${customText.length}/150 caractères`}
            />
          </Box>

          <Box sx={{ marginTop: 3 }}>
            <Typography variant="h6">Choisir un visuel :</Typography>
            <Grid container spacing={2} sx={{ marginTop: 1 }}>
              {visuals.map((url, index) => (
                <Grid item xs={3} key={index}>
                  <Paper
                    elevation={selectedVisual === url ? 4 : 1}
                    sx={{
                      cursor: 'pointer',
                      border: selectedVisual === url ? '2px solid #c48f8b' : 'none',
                      padding: 1,
                      textAlign: 'center',
                    }}
                    onClick={() => handleVisualSelection(url)}
                  >
                    <img
                      src={`https://back.atoneo.com${url}`}
                      alt={`Visuel ${index + 1}`}
                      style={{ width: '100%', borderRadius: '4px' }}
                    />
                  </Paper>
                </Grid>
              ))}
            </Grid>
          </Box>

          <Divider sx={{ marginY: 2 }} />

          <Button 
            variant="contained" 
            color="secondary" 
            onClick={handleAddToCart} 
            sx={{ marginTop: 2 }}
          >
            Ajouter au Panier
          </Button>

          <Typography variant="body2" color="text.secondary" sx={{ marginTop: 2 }}>
            Date de création: {new Date(product.createdAt).toLocaleDateString()}
          </Typography>

          {product.joli_mot_categories?.data?.length > 0 && (
            <Box sx={{ marginTop: 2 }}>
              <Typography variant="body2" color="text.secondary">
                Catégories:
              </Typography>
              <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap', marginTop: 1 }}>
                {product.joli_mot_categories.data.map((category) => (
                  <Chip
                    key={category.id}
                    label={category.attributes.categoryName}
                    component={Link}
                    to={`/category/${category.attributes.idCateg}`}
                    clickable
                    sx={{ backgroundColor: '#f8bbd0', color: '#FFFFFF' }}  
                  />
                ))}
              </Box>
            </Box>
          )}
          
        </Grid>
      </Grid>

      <Typography variant="h5" sx={{ marginTop: 4 }}>
        Détails du produit
      </Typography>

      {markdownSections.map((section, index) => (
        <Accordion key={index} sx={{ marginTop: 1, boxShadow: 'none' }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <HeadingComponent title={section.title} level={section.level} />
          </AccordionSummary>
          <AccordionDetails>
            <ReactMarkdown>{section.content}</ReactMarkdown>
          </AccordionDetails>
        </Accordion>
      ))}

      <ToastContainer />
    </>
  );
};

export default ProductDetail;

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ScratchCard from './ScratchCard';
import ModifCartePanel from './ModifCartePanel';
import { QRCodeSVG } from 'qrcode.react';
import Typography from '@mui/material/Typography';


const ScratchCardPage = () => {
  const { id } = useParams();
  const [config, setConfig] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [textSize, setTextSize] = useState(24);
  const [textColor, setTextColor] = useState('#000000');
  const [message, setMessage] = useState('');
  const [brushSize, setBrushSize] = useState(30);
  const [marginTop, setMarginTop] = useState(0);
  const [textHeight, setTextHeight] = useState(0); // Nouvelle valeur pour la hauteur
  const [lineHeight, setLineHeight] = useState(45); // Nouvelle valeur pour l'interligne
  const [isSubmitting, setIsSubmitting] = useState(false); // Indicateur de soumission
  const [submitSuccess, setSubmitSuccess] = useState(null); // Statut de la soumission

  // Gérer le texte du bouton
  const [buttonText, setButtonText] = useState('Enregistrer les modifications');

  // Fetch initial data
  useEffect(() => {
    const API_URL = `https://joli-mot.fr/apiEtsy.php?cleApi=34KVHFQSP226YTk4n256QP6rGbqhA5w2ymY7W3pLwdbuc3i75&service=getCarte&id=${id}`;
    
    fetch(API_URL)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        setConfig(data);
        setMessage(decodeHtml(data.message || ''));
        setTextSize(data.taille_text || 24);
        setTextColor(data.couleur_text || '#000000');
        setBrushSize(30);
        setMarginTop(data.margin_top_gratte || 0);
        setLineHeight(data['line-height'] || 45); // Récupération de l'interligne
        setTextHeight(data['text-margin-top'] || 0); // Récupération de l'interligne
        setLoading(false);
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      });
  }, [id]);

  const decodeHtml = (html) => {
    const txt = document.createElement('textarea');
    txt.innerHTML = html;
    return txt.value;
  };

  const getRandomInt = (min, max) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  // Nouvelle fonction pour obtenir la date actuelle (format YYYY-MM-DD)
  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Mois avec deux chiffres
    const day = String(today.getDate()).padStart(2, '0'); // Jour avec deux chiffres
    return `${year}-${month}-${day}`;
  };

  // Fonction pour soumettre les modifications via l'API en GET
  const handleSubmit = (e) => {
    e.preventDefault(); // Empêche le rechargement de la page
    setIsSubmitting(true); // Démarrage du chargement
    setSubmitSuccess(null); // Réinitialiser le succès/erreur
    setButtonText('Envoi des données...'); // Changement du texte du bouton

    const updatedCarte = {
      id,
      message, // Pas besoin d'encodeURIComponent ici
      textSize,
      textColor, // Pas besoin d'encodeURIComponent ici
      marginTop,
      textHeight,
      lineHeight,
      dateCreation: getCurrentDate(), // On met à jour la date actuelle
    };

    // Construction de l'URL avec les paramètres GET
    const queryParams = new URLSearchParams(updatedCarte).toString();
    const url = `https://joli-mot.fr/apiEtsy.php?cleApi=34KVHFQSP226YTk4n256QP6rGbqhA5w2ymY7W3pLwdbuc3i75&service=upCarte&${queryParams}`;

    console.log('URL envoyée à l\'API :', url);

    // Envoi de la requête GET
    fetch(url)
      .then(response => {
        if (!response.ok) {
          throw new Error(`Erreur HTTP: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        setIsSubmitting(false); // Arrêt du chargement
        console.log('Réponse de l\'API :', data);

        if (data.success) {
          setSubmitSuccess(true); // Affichage de la confirmation de succès
          setButtonText('Modification réussie !'); // Changement du texte du bouton
        } else {
          setSubmitSuccess(false); // Affichage de l'erreur
          setButtonText('Erreur lors de l\'enregistrement'); // Texte du bouton en cas d'erreur
        }
      })
      .catch(error => {
        setIsSubmitting(false); // Arrêt du chargement
        setSubmitSuccess(false); // Affichage de l'erreur
        setButtonText('Erreur lors de l\'enregistrement'); // Texte du bouton en cas d'erreur
        console.error('Erreur lors de l\'enregistrement des modifications', error);
      });
  };

  const previewURL = `https://joli-mot.fr/carte-a-gratter/${id}&${getRandomInt(123456, 45943284588234)}`;

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <div className="scratch-card-page">
      <div className="page-content">
        <div className="scratch-card-section">
          <p className='accrocheProduit'>Produit : Carte a gratter virtuelle<br /><span>2,20€ TTC</span></p>
          <p className='info'>Grattez-moi et modifiez moi...<br />avant de m'envoyer :</p>
          <ScratchCard
            width={400}
            height={400}
            gratteSrc={`/assets_react/grattes/${config.gratte}.png`}
            brushSize={brushSize}
            text={message}
            imageSrc={`/assets_react/visuels/${config.visuel}`}
            textSize={textSize}
            textColor={textColor}
            marginTop={marginTop}
            textHeight={textHeight} // Passer l'interligne à ScratchCard
            lineHeight={lineHeight} // Passer l'interligne à ScratchCard
          />
        </div>
        <div className="modif-carte-section">
          <ModifCartePanel
            textSize={textSize}
            setTextSize={setTextSize}
            textColor={textColor}
            setTextColor={setTextColor}
            message={message}
            setMessage={setMessage}
            textHeight={textHeight}
            lineHeight={lineHeight}
            setTextHeight={setTextHeight}
            setLineHeight={setLineHeight}
            onSubmit={handleSubmit}
            isSubmitting={isSubmitting}
            buttonText={buttonText} // Passer le texte du bouton et l'état
          />

          {/* Indicateur visuel de soumission */}
          {submitSuccess === true && <p style={{ color: 'green' }}>Modifications enregistrées avec succès !</p>}
          {submitSuccess === false && <p style={{ color: 'red' }}>Erreur lors de l'enregistrement des modifications.</p>}

          <div className="share-info">
            <p>Une fois que vous êtes satisfait de la carte, vous pouvez la prévisualiser et la partager en utilisant le lien ci-dessous :</p>
            <Typography variant="h3" color="secondary" gutterBottom>{previewURL}</Typography>
            <br />
            <br />
            <p>Ou en imprimant le QrCode ci-dessous :</p>
            <div className="qrcode-container">
              <QRCodeSVG value={previewURL} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScratchCardPage;

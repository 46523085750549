import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Button, Typography, Table, TableBody, TableCell, TableHead, TableRow, Select, MenuItem, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import PasswordProtect from "./PasswordProtect";

const formatDate = (dateString) => {
  if (!dateString) return "Date inconnue";

  const date = new Date(dateString);
  return date.toLocaleDateString("fr-FR", {
    weekday: "long", // Nom du jour (ex : lundi, mardi)
    day: "numeric",
    month: "long", // Nom complet du mois (ex : janvier, février)
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });
};

const getOrderClass = (cmd_increment) => {
  if (cmd_increment.startsWith("ETSY")) {
    return "etsy-order"; // Classe CSS pour Etsy
  } else if (cmd_increment.startsWith("CMD")) {
    return "direct-order"; // Classe CSS pour commandes directes
  }
  return ""; // Aucune classe si inconnu
};



const OrderManagement = () => {
  const [orders, setOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [paymentDetails, setPaymentDetails] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [emailStatus, setEmailStatus] = useState({}); // Stocke le statut d'envoi de l'e-mail pour chaque commande

  const [page, setPage] = useState(1);
const [hasMore, setHasMore] = useState(true);
const ORDERS_PER_PAGE = 10;


const fetchOrders = (pageNumber) => {
  axios.get(`https://joli-mot.fr/apiEtsy.php?cleApi=34KVHFQSP226YTk4n256QP6rGbqhA5w2ymY7W3pLwdbuc3i75&service=getOrders&page=${pageNumber}&limit=${ORDERS_PER_PAGE}`)
    .then(async (response) => {
      if (response.data.length < ORDERS_PER_PAGE) {
        setHasMore(false); // Désactiver le bouton "Charger plus" s'il n'y a plus de commandes à charger
      }
      const ordersWithUserDetails = await Promise.all(
        response.data.map(async (order) => {
          const userResponse = await axios.get(`https://joli-mot.fr/apiEtsy.php?cleApi=34KVHFQSP226YTk4n256QP6rGbqhA5w2ymY7W3pLwdbuc3i75&service=getUser&user_id=${order.user_id}`);
          return { ...order, user: userResponse.data };
        })
      );
      setOrders((prevOrders) => [...prevOrders, ...ordersWithUserDetails]); // Ajout progressif des nouvelles commandes
    })
    .catch((error) => {
      console.error('Erreur lors du chargement des commandes:', error);
    });
};


useEffect(() => {
  fetchOrders(page);
}, []);

const loadMoreOrders = () => {
  setPage((prevPage) => prevPage + 1);
  fetchOrders(page + 1);
};


  const handleStatusChange = (cmd_increment, newStatus) => {
    axios.get(`https://joli-mot.fr/apiEtsy.php?cleApi=34KVHFQSP226YTk4n256QP6rGbqhA5w2ymY7W3pLwdbuc3i75&service=updateOrderStatus&order_id=${cmd_increment}&status=${newStatus}`)
      .then((response) => {
        const cleanResponse = response.data.split('}{').join('},{');
        const parsedResponse = JSON.parse(`[${cleanResponse}]`);
  
        const statusUpdateResponse = parsedResponse[0];
        if (statusUpdateResponse.success) {
          console.log('Statut mis à jour avec succès:', statusUpdateResponse.message);
          setOrders(prevOrders => 
            prevOrders.map(order => 
              order.cmd_increment === cmd_increment ? { ...order, status: newStatus } : order
            )
          );
        } else {
          console.error('Erreur lors de la mise à jour du statut:', statusUpdateResponse.message);
        }
      })
      .catch((error) => {
        console.error('Erreur lors de la mise à jour du statut ou de la récupération des détails:', error);
      });
  };

  const handleViewDetails = (orderId) => {
    axios.get(`https://joli-mot.fr/apiEtsy.php?cleApi=34KVHFQSP226YTk4n256QP6rGbqhA5w2ymY7W3pLwdbuc3i75&service=getOrderDetails&order_id=${orderId}`)
      .then((response) => {
        setSelectedOrder(response.data);
        setIsModalOpen(true);

        return axios.get(`https://joli-mot.fr/apiEtsy.php?cleApi=34KVHFQSP226YTk4n256QP6rGbqhA5w2ymY7W3pLwdbuc3i75&service=getPayment&order_id=${orderId}`);
      })
      .then((response) => {
        setPaymentDetails(response.data);
      })
      .catch((error) => {
        console.error('Erreur lors du chargement des détails:', error);
      });
  };

  const handleSendOrderSummary = (orderId) => {
    axios.get(`https://joli-mot.fr/apiEtsy.php?cleApi=34KVHFQSP226YTk4n256QP6rGbqhA5w2ymY7W3pLwdbuc3i75&service=sendOrderSummary&order_id=${orderId}`)
      .then((response) => {
        if (response.data.success) {
          setEmailStatus(prevStatus => ({ ...prevStatus, [orderId]: 'success' })); // Marquer comme succès
          console.log('Récapitulatif de commande envoyé avec succès:', response.data.message);
        } else {
          setEmailStatus(prevStatus => ({ ...prevStatus, [orderId]: 'error' })); // Marquer comme erreur
          console.error('Erreur lors de l\'envoi du récapitulatif:', response.data.message);
        }
      })
      .catch((error) => {
        setEmailStatus(prevStatus => ({ ...prevStatus, [orderId]: 'error' })); // Marquer comme erreur
        console.error('Erreur lors de l\'appel au service sendOrderSummary:', error);
      });
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedOrder(null);
    setPaymentDetails(null);
  };

  return (
    <PasswordProtect>
    <div style={{ padding: '20px' }}>
      <Typography variant="h1" gutterBottom>Gestion des Commandes</Typography>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>ID Commande</TableCell>
            <TableCell>Date</TableCell>
            <TableCell>Utilisateur</TableCell>
            <TableCell>Statut</TableCell>
            <TableCell>Total TTC</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {orders.map(order => (
            <TableRow key={order.id}>
              <TableCell>
                <span className={getOrderClass(order.cmd_increment)}>{order.cmd_increment}</span>
              </TableCell>

              <TableCell>{formatDate(order.created_at)}</TableCell>
              <TableCell>
                <Typography variant="body2"><strong>Nom :</strong> {order.user.name}</Typography>
                <Typography variant="body2"><strong>Email :</strong> {order.user.email}</Typography>
                <Typography variant="body2"><strong>Adresse :</strong> {order.user.address}, {order.user.city}, {order.user.postal_code}, {order.user.country}</Typography>
              </TableCell>
              <TableCell>
                <Select
                  value={order.status}
                  onChange={(e) => handleStatusChange(order.cmd_increment, e.target.value)}
                >
                  <MenuItem value="pending">En attente</MenuItem>
                  <MenuItem value="completed">Terminée</MenuItem>
                  <MenuItem value="cancelled">Annulée</MenuItem>
                  <MenuItem value="aborted">Abandonnée</MenuItem>
                </Select>
              </TableCell>
              <TableCell>{order.total_ttc} €</TableCell>
              <TableCell>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleViewDetails(order.id)}
                  style={{ marginRight: '10px' }}
                >
                  Détails
                </Button>
                <Button
                  variant="contained"
                  onClick={() => handleSendOrderSummary(order.cmd_increment)}
                  style={{
                    backgroundColor: emailStatus[order.cmd_increment] === 'success' ? '#4CAF50' : emailStatus[order.cmd_increment] === 'error' ? '#f44336' : '',
                    color: emailStatus[order.cmd_increment] ? '#FFFFFF' : '',
                  }}
                >
                  Mail direct Récap. cmd.
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {hasMore && (
  <Button variant="contained" color="primary" onClick={loadMoreOrders} style={{ marginTop: '20px' }}>
    Charger plus
  </Button>
)}


      {/* Modal pour les détails de la commande */}
      <Dialog open={isModalOpen} onClose={handleCloseModal} fullWidth maxWidth="md">
        <DialogTitle>Détails de la commande {selectedOrder?.cmd_increment}</DialogTitle>
        <DialogContent>
          {selectedOrder && (
            <>
              <Typography variant="body1"><strong>Statut :</strong> {selectedOrder.status}</Typography>
              <Typography variant="body1"><strong>Total TTC :</strong> {selectedOrder.total_ttc} €</Typography>

              {paymentDetails && (
                <>
                  <Typography variant="h6" gutterBottom>Détails du paiement</Typography>
                  <Typography variant="body1"><strong>Méthode de paiement :</strong> {paymentDetails.payment_method}</Typography>
                  <Typography variant="body1"><strong>Montant :</strong> {paymentDetails.amount} €</Typography>
                  <Typography variant="body1"><strong>Transaction ID :</strong> {paymentDetails.transaction_id}</Typography>
                  <Typography variant="body1"><strong>Statut du paiement :</strong> {paymentDetails.payment_status}</Typography>
                </>
              )}

              {selectedOrder.cards && selectedOrder.cards.length > 0 && (
                <>
                  <Typography variant="h6" gutterBottom>Détails des Cartes</Typography>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>ID Carte</TableCell>
                        <TableCell>Client</TableCell>
                        <TableCell>Visuel</TableCell>
                        <TableCell>Message</TableCell>
                        <TableCell>Titre</TableCell>
                        <TableCell>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {selectedOrder.cards.map((card, index) => (
                        <TableRow key={index}>
                          <TableCell>{card.id}</TableCell>
                          <TableCell>{card.client}</TableCell>
                          <TableCell>{card.visuel}</TableCell>
                          <TableCell>{card.message}</TableCell>
                          <TableCell>{card.titre}</TableCell>
                          <TableCell>
                            <Button
                              variant="contained"
                              color="primary"
                              href={`/edit-card/${selectedOrder.id}/${card.id}`}
                            >
                              Modifier
                            </Button>
                            </TableCell>
                          <TableCell>
                            <Button
                              variant="contained"
                              color="secondary"
                              href={`/voir-ma-carte/${card.id}`}
                            >
                              Visuel
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </>
              )}
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="secondary">Fermer</Button>
        </DialogActions>
      </Dialog>
    </div>
    </PasswordProtect>
  );
};

export default OrderManagement;
